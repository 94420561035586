import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: "Tudor Stanciu's portfolio", // e.g: 'Name | Developer'
  lang: '', // e.g: en, es, fr, jp
  description: "Tudor Stanciu's portfolio", // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: '',
  name: 'Tudor Stanciu',
  subtitle: "I'm a passionate full stack software engineer",
  cta: '',
};

// ABOUT DATA
export const aboutData = {
  img: 'profile.jpg',
  paragraphOne: 'For me, programming is first a passion and then a job. I think coding is an art.',
  paragraphTwo:
    'Currently my main professional activity as a senior engineer involves a lot of software work, but in my spare time I expand my knowledge sphere in new areas such as hardware, networking, virtualization and DevOps.',
  paragraphThree:
    'To combine all the technologies I practice and study, I built a home lab with all that it means.',
  resume: 'https://lab.code-rove.com/ts-cv', // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'homelab-network-architecture.png',
    title: 'Home lab',
    info: 'This lab is my playground. It was started some time ago, when I was writing the first applications and questions like "Where do I host this application now? How do I expose it on internet?" have begun to appear. This is how the first web server was installed...',
    info2: '',
    button1: 'Open',
    button2: 'Lab Story',
    url: 'https://lab.code-rove.com/docs/uploads/images/drawio/2023-01/drawing-3-1673219947.png',
    repo: 'https://lab.code-rove.com/lab-stories/summary', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'reverse-proxy.png',
    title: 'Reverse proxy',
    info: 'This reverse proxy is the guardian of my lab. It protects and manage the only open gate to my creation space.',
    info2:
      'I wrote it with C# language and a little help from the ProxyKit nuget package. Now it has a lot of customizations and new features than an existing open source tool.',
    url: 'https://lab.code-rove.com/reverse-proxy',
    repo: 'https://lab.code-rove.com/gitea/tudor.stanciu/reverse-proxy-frontend', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'network-resurrector.png',
    title: 'Network resurrector',
    info: "Everything must be able to be managed remotely. Even the powered off servers. That's how Network resurrector appeared, the tool I wrote specifically to be able to wake up my machines that I don't need to be powered on all the time.",
    info2:
      'In the usual state, the lab is maintained only by low power components, but when I connect remotely and want to develop something, a tool like this becomes indispensable.',
    url: 'https://lab.code-rove.com/network-resurrector',
    repo: 'https://lab.code-rove.com/gitea/tudor.stanciu/network-resurrector', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'heimdall-dashboard.png',
    title: '...and many others',
    info: 'My career in this field began in the summer of 2015. Since then, many experiments or small applications have come out from my keyboard. In the same time, other pieces of software from the open source world have joined the playground.',
    info2:
      'To see most of these, check my Heimdall dashboard. There is even the first application I wrote (it has a suggestive name).',
    url: 'https://lab.code-rove.com/heimdall',
    repo: '',
  },
];

// CONTACT DATA
export const contactData = {
  cta: '',
  btn: '',
  email: 'tudor.stanciu94@gmail.com',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/tudor-stanciu-343b39147/',
    },
    {
      id: nanoid(),
      name: 'server',
      url: 'https://lab.code-rove.com/lab-stories/',
    },
    {
      id: nanoid(),
      name: 'th',
      url: 'https://lab.code-rove.com/heimdall',
    },
    {
      id: nanoid(),
      name: 'github', // https://fontawesome.com/v5.15/icons/github
      url: 'https://lab.code-rove.com/gitea/',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false, // set to false to disable the GitHub stars/fork buttons
};
